<template>
  <div class="page blog-post">
    <div class="wrapper">
      <PageHeader v-if="pageHeader" :title="pageHeader.title" :subtitle="pageHeader.subtitle" />
      <div class="post-content" v-html="content"></div>
    </div>
  </div>
</template>

<script>
import PageHeader from '../components/PageHeader.vue';

export default {
  name: 'PolicyPage',
  components: {
    PageHeader,
  },
  data() {
    return {
      content: '',
    };
  },
  computed: {
    pageHeader() {
      switch (this.$route.name) {
        case 'patch-notes':
          return {
            title: 'Patch Notes',
            subtitle: 'Learn more about our most recent updates!',
          };
        default:
          return null;
      }
    },
  },
  watch: {
    $route() {
      this.getContent();
    },
  },
  methods: {
    async getContent() {
      this.content = '';
      const fileName = this.$route.name;
      const data = await fetch(`/html/${fileName}.html`);
      this.content = await data.text();
    },
  },
  mounted() {
    this.getContent();
  },
};
</script>

<style scoped>
.page {
  padding: 40px 0 0;
}

.wrapper {
  width: 1056px;
}

.post-categories {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;
}

.post-category {
  background-color: #fff;
  color: #F36;
  padding: 6px 12px;
  border-radius: 28px;
  font-weight: bold;
  font-size: 14px;
}

.post-views {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-left: 12px;
  color: rgba(255, 255, 255, 0.69);
}

h1 {
  font-size: 40px;
  margin-bottom: 12px;
}

h2 {
  font-size: 20px;
  font-weight: 600;
}

.post-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 24px;
}

.post-meta {
  display: flex;
  align-items: center;
  gap: 8px;
  text-transform: uppercase;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.69);
}

.post-cover {
  margin-top: 32px;
  margin-bottom: 32px;
}

.post-share {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 600;
}

.post-share .icon-button {
  background-color: #393652;
}

.post-author {
  border-right: 1px solid;
  padding-right: 8px;
}

.post-author span {
  color: #F36;
}

.post-cover-image {
  display: block;
  height: 560px;
  width: calc(100% + 36px);
  margin-left: -18px;
  object-fit: cover;
  margin-bottom: 12px;
}

.post-navigation {
  display: flex;
  justify-content: space-between;
  padding-top: 24px;
  margin-top: 88px;
  border-top: 1px dashed rgba(255, 255, 255, 0.46);
}

.post-nav-item {
  width: 50%;
}

.post-nav-item.previous {
  padding-right: 48px;
  border-right: 1px dashed rgba(255, 255, 255, 0.46);
}

.post-nav-item.next {
  padding-left: 48px;
}

.post-box {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 24px 32px;
  background: #1F1D2C;
  gap: 24px;
  border-radius: 16px;
  color: rgba(255, 255, 255, 0.69);
}

.post-box .post-box-title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 8px;
  color: #fff;
}

.post-box .icon-button {
  background: #393652;
}

.post-box .post-box-content {
  flex-grow: 1;
}

.post-content {
  font-size: 18px;
  line-height: 29.124px;
}

.post-content >>> * {
  color: #fff !important;
}

.post-content >>> h1,
.post-content >>> h2,
.post-content >>> h3,
.post-content >>> h4,
.post-content >>> h5,
.post-content >>> h6,
.post-content >>> p,
.post-content >>> img,
.post-content >>> figcaption,
.post-content >>> blockquote {
  display: block;
  margin-top: 0;
  margin-bottom: 32px;
}

.post-content >>> h1 {
  font-size: 32px;
}

.post-content >>> h2 {
  font-size: 24px;
}

.post-content >>> h3 {
  font-size: 20px;
}

.post-content >>> h4 {
  font-size: 18px;
}

.post-content >>> h5 {
  font-size: 16px;
}

.post-content >>> h6 {
  font-size: 14px;
}

.post-content >>> img {
  width: 100%;
}

.post-content >>> ul {
  margin: 0 0 32px;
}

.post-content >>> section:not(:last-child) {
  background: url(../assets/ic-h-line.svg) repeat-x bottom left/auto 2px;
  padding-bottom: 32px;
  margin-bottom: 64px;
}

.post-cover figcaption,
.post-content >>> figcaption {
  display: block;
  color: rgba(255, 255, 255, 0.69);
  font-size: 14px;
}

.post-content >>> figcaption {
  margin-top: -24px;
  border-bottom: 1px dashed rgba(255, 255, 255, 0.46);
}

.post-author-bio,
.post-content >>> blockquote {
  margin-left: 0;
  margin-right: 0;
  padding: 12px 0;
  font-size: 16px;
  line-height: normal;
  font-style: italic;
  border-top: 1px dashed rgba(255, 255, 255, 0.46);
  border-bottom: 1px dashed rgba(255, 255, 255, 0.46);
}

.post-author-bio {
  margin: 32px 0;
}

.post-content >>> a,
.post-content >>> a * {
  color: #F36 !important;
  text-decoration: underline;
}

@media screen and (max-width: 1024px) {
  .post-cover-image {
    height: 400px;
  }
}

@media screen and (max-width: 800px) {
  .post-nav-item.previous {
    padding-right: 24px;
  }
  .post-nav-item.next {
    padding-left: 24px;
  }
  .post-box {
    padding: 16px;
    gap: 16px;
  }
  .post-box .post-box-title {
    font-size: 18px;
  }
}

@media screen and (max-width: 580px) {
  .post-meta {
    flex-direction: column;
    align-items: flex-start;
  }
  .post-author {
    border: none;
  }
  .post-nav-item.previous {
    padding-right: 12px;
  }
  .post-nav-item.next {
    padding-left: 12px;
  }
  .post-box .icon-button {
    display: none;
  }
}
</style>

<template>
  <div class="title" :no-margin="noMargin">
    <h1>{{ title }}</h1>
    <span>{{ subtitle }}</span>
  </div>
</template>

<script>
export default {
  name: 'PageHeader',
  props: {
    title: String,
    subtitle: String,
    noMargin: Boolean,
  },
};
</script>

<style scoped>
.title {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px 16px;
  margin-bottom: 64px;
}

.title[no-margin] {
  margin-bottom: 0;
}

.title h1 {
  position: relative;
  text-transform: uppercase;
  font-size: 36px;
}

.title h1:after {
  content: '';
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  width: 83px;
  height: 23px;
  margin-top: -4px;
  background: url(../assets/title-art.svg) no-repeat center/contain;
}

@media screen and (max-width: 480px) {
  .title {
    text-align: center;
    flex-direction: column;
    align-items: center;
  }
  .title h1:after {
    left: 0;
    margin: -4px auto 0;
  }
}
</style>
